import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import ru from 'react-phone-input-2/lang/ru.json';
import 'react-phone-input-2/lib/style.css';

import styles from './PhoneField.scss';

type Props = {
    className?: string;
    isDisabled?: boolean;
    value?: string;
    onChange: (value: string) => void;
};

const PhoneField = React.forwardRef(({ className, isDisabled, value, onChange }: Props, ref) => {
    const { t } = useTranslation(['controls']);

    return (
        <PhoneInput
            disabled={isDisabled}
            localization={ru}
            country="ru"
            value={value}
            inputClass={styles.input}
            specialLabel={t('PHONE_FIELD_LABEL')}
            containerClass={classNames(styles.inputWrap, className)}
            buttonClass={styles.btn}
            dropdownClass={styles.dropdown}
            inputProps={{
                name: 'phone',
                ref: ref || undefined,
            }}
            onChange={(val) => onChange(val)}
        />
    );
});

export default PhoneField;
